import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import useIsMobile from '../../hooks/useIsMobile';
import AnimatedCursor from '../../components/animated-cursor/animated-cursor'
import './about-me.css';
import { CircularButton } from '../../components/shared-proj-components';

const columnData = [
  { header: 'RESEARCH', words: ['User Interviews', 'Usability Testing', 'Focus Groups'] },
  { header: 'DESIGN', words: ['Wireframes', 'Prototypes', 'High Fidelity Design', 'User Flows', 'User Personas'] },
  { header: 'TOOLS', words: ['Figma', 'Invision', 'Sketch', 'Adobe Suite', 'Dovetail', 'Jira', 'Confluence', 'Aha', 'Rhino'] }
];

function App() {
  const { isMobile, width} = useIsMobile();

  return (
    <div>
            <Header />
           <div style={{ 
            ...styles.app, ...(isMobile && { height: 'auto' }),
          maxWidth: 1660, margin: '0 auto', alignSelf: 'center' 
        }}>
      <AnimatedCursor
        innerSize={5}
        outerSize={40}
        color='0, 0, 0'
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={1.5}
      />
      <div style={{...styles.mainContainer, ...(isMobile && mobileStyles.mainContainer)}}>
        <div style={{ display: 'flex', flexDirection: 'row '}}>
          {!isMobile && <div style={{...styles.column1, ...({ alignItems: 'center',  justifyContent: 'center'})}}>
            <CircularButton 
              borderColor='#000'
              textColor='#fff'
              backgroundColor='#000'
              link={"#/#projects"} 
              text="UX Projects" />
          </div>}
          <div style={{...styles.column2, ...(width < 1400 && { flex: 2 }), ...(isMobile && { paddingBottom: 80 })}}>
            <div style={styles.aboutMe}>I am a UX designer with a HBA in Architectural design. I went from designing physical spaces to digital interfaces.</div>
            <div style={{ lineHeight: '25px', fontSize: '1.125rem' }}><br />My professional journey led me through various startups as a UI/UX designer, where I've had the privilege of working both in SAAS products and freelance environments. I am passionate about web accessibility, atomic design systems, and interaction design.<br /><br /></div>
            <div style={{ ...styles.aboutMe, width: '100%' }}><br />Skills</div>
            <div className="columns-container">
              {columnData.map((column, index) => (
                <div key={index} className="column">
                  <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>{column.header}</span>
                  <ul>
                    {column.words.map((word, wordIndex) => (
                      <li key={wordIndex}>{word}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        {!!isMobile && 
          <CircularButton 
            borderColor='#000'
            textColor='#fff'
            backgroundColor='#000'
            link={"#/#projects"} 
            text="UX Projects" />
        }
      </div> 
      {/* <div style={{flex:1}}/> */}
      <div style={{ marginTop: isMobile ? 0 : -152 }}>
        <Footer /> 
      </div>
    </div>
    </div>
  );
}
const styles = {
  app: {
    position: 'relative',
    display: 'flex',
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 200px',
    height: '100%'
  },

  column1: {
    // flex: 1,
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  column2: {
    // flex: 1,
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  aboutMe: {
    fontSize: '2rem',
    fontFamily: 'Grifo',
    fontWeight: 700,
  },

  projectsButton: {
    fontSize: 18,
    width: 144,
    height: 144,
    backgroundColor: '#000000',
    textDecoration: 'none',
    borderRadius: 80,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}

const mobileStyles = {
  mainContainer: {
    margin: '200px 24px 100px ',
    flexDirection: 'column', 
    height: 'auto',
  }
}

export default App;
