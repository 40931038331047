import React, { useRef } from 'react'

import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import atmin_image from '../../assets/atmin-project-title-image.png';
import atmin_persona_1 from '../../assets/atmin-persona-1.jpg';
import atmin_persona_2 from '../../assets/atmin-persona-2.jpg';
import atmin_ia from '../../assets/atmin-IA.jpg';
import atmin_taskflow from '../../assets/atmin-taskflow.jpg';
import atmin_sketch_1 from '../../assets/atmin-sketch-1.jpg';
import atmin_sketch_2 from '../../assets/atmin-sketch-2.jpg';
import atmin_sketch_3 from '../../assets/atmin-sketch-3.jpg';

import atmin_wireframe_1 from '../../assets/atmin-wireframes-1.jpg';
import atmin_wireframe_2 from '../../assets/atmin-wireframes-2.jpg';
import atmin_wireframe_3 from '../../assets/atmin-wireframes-3.jpg';
import atmin_wireframe_4 from '../../assets/atmin-wireframes-4.jpg';
import atmin_wireframe_5 from '../../assets/atmin-wireframes-5.jpg';
import atmin_wireframe_6 from '../../assets/atmin-wireframes-6.jpg';
import atmin_wireframe_7 from '../../assets/atmin-wireframes-7.jpg';
import atmin_wireframe_8 from '../../assets/atmin-wireframes-8.jpg';
import atmin_wireframe_9 from '../../assets/atmin-wireframes-9.jpg';
import atmin_wireframe_10 from '../../assets/atmin-wireframes-10.jpg';
import atmin_wireframe_11 from '../../assets/atmin-wireframes-11.jpg';
import atmin_wireframe_12 from '../../assets/atmin-wireframes-12.jpg';


import atmin_library_video from '../../assets/atmin_library_video_min.mp4';
import atmin_task_video from '../../assets/atmin_task_video_min.mp4';
import atmin_note_video from '../../assets/atmin_note_video_min.mp4';
import atmin_todo_video from '../../assets/atmin_todo_video_min.mp4';


import 'react-medium-image-zoom/dist/styles.css'

import './projects.css';
import useIsMobile from '../../hooks/useIsMobile';

import AnimatedCursor from '../../components/animated-cursor/animated-cursor'

import { SectionTitle, SubsectionTitle, NextProjectSection, ProjectTitleSection } from '../../components/shared-proj-components';
import SideNav from '../../components/side-nav/SideNav';

// import atmin_project_image from '../../assets/atmin-project-image.png'
// import ob_project_image from '../../assets/ob-project-image.png'
// import off2class_project_image from '../../assets/off2class-project-image.png'

const prototype_link = "https://www.figma.com/proto/NhfD3bdhUoh3JDbvvHGXlv/Atmin?page-id=0%3A1&node-id=459%3A6436&viewport=241%2C48%2C0.2&scaling=scale-down&starting-point-node-id=459%3A6436&show-proto-sidebar=1";

function AtminProjectPage() {
  const { isMobile, width } = useIsMobile();
  const sections = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'research', title: 'Research' },
    { id: 'ia', title: 'Information Architecture' },
    { id: 'wireframing', title: 'Wireframing' },
    { id: 'design', title: 'UI Design' }
  ];
  
  const sectionsRef = useRef([]);

  return (<div style={{ backgroundColor: 'black' }} className="app">
      <Header light/>
      <SideNav items={sections} textColor={'#80F3CA'} sectionsRef={sectionsRef} />
      <div style={{ 
        maxWidth: 1660, margin: '0 auto', alignSelf: 'center' 
      }}>
        <AnimatedCursor
          innerSize={5}
          outerSize={40}
          color='255, 255, 255'
          outerAlpha={0.2}
          innerScale={0.5}
          outerScale={1.5}
        />
        <ProjectTitleSection 
          color={'#E0BE74'}
          subtitleColor={'#FFFFFF'}
          title={'atmin'} 
          subtitle={'UX DESIGN'} 
          date={'2020 - 2021'} 
          description={'atmin is a productivity app with the ability to write notes and tasks all in one place!'} 
          image={atmin_image}
          sectionsRef={sectionsRef}
        />
        <SectionTitle title='Design Process' color='#E0BE74' />
        <div style={{...style.stepContainer, ...(isMobile && mobileStyle.stepContainer)}}>
          {!isMobile && <div style={{ display: 'flex', flex: 2 }}>
            <a href={prototype_link} target="_blank" rel="noreferrer" style={style.button_alternate} className="circular-button-dark">
              View< br />Prototype
            </a>
          </div>}
          
          <div style={{ display: 'flex', flexWrap: 'wrap', flex: 4}}>
            <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, marginBottom: 24, flexDirection: 'row', ...(isMobile && {justifyContent: 'center'}) }}>
              <div className="design-process-step design-process-number">1.</div>
              <div>
                <div style={style.designProcessStep}>Research</div>
                <div className="design-process-substep">User Interview</div>
                <div className="design-process-substep">Personas</div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, marginBottom: 24, flexDirection: 'row' }}>
              <div className="design-process-step design-process-number">2.</div>
              <div>
                <div style={style.designProcessStep}>Information Architecture</div>
                <div className="design-process-substep">IA</div>
                <div className="design-process-substep">Task Flows</div>
                <div className="design-process-substep">Sketches</div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, marginBottom: 24, flexDirection: 'row', ...(isMobile && {justifyContent: 'center'}) }}>
              <div className="design-process-step design-process-number">3.</div>
              <div>
                <div style={style.designProcessStep}>Wireframing</div>
                <div className="design-process-substep">Wireframes</div>
                <div className="design-process-substep">Testing</div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, marginBottom: 24, flexDirection: 'row' }}>
              <div className="design-process-step design-process-number">4.</div>
              <div>
                <div style={style.designProcessStep}>UI Design</div>
                <div className="design-process-substep">Style Guide</div>
                <div className="design-process-substep">UI Design</div>
              </div>
            </div>
            {!!isMobile && <div style={{ width: '100%', marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40}}>
              <a href={prototype_link} target="_blank" rel="noreferrer" style={style.button} className="circular-button-dark">
                      View<br />Prototype
                    </a>
            </div>}
          </div>
        </div>
        
        <div id="introduction" ref={(el) => (sectionsRef.current[0] = el)}  style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          {/* <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
              <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Introduction</div>
              {!!isMobile && <div style={{...style.sectionLine, ...(isMobile && mobileStyle.sectionLine)}} />}
          </div> */}
          <SubsectionTitle title='Introduction' color='#B59250' lineColor='#E0BE74' />
          
          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.atminIntroductionText}}>
            <span>While on the hunt for a good note app that also functions as a task app, I was left with two options: either using multiple apps to stay organized and accomplish my tasks, or to use a productivity app that was both difficult to use and very overwhelming to learn.</span>
            
            <span><br/>The problem I wanted to solve was the additional steps users are forced to take to stay organized throughout the process of taking notes by designing an app that:</span>

            <ul>
                  <li style={{ marginBottom: 8 }}>Combines notes and task functions all in one place</li>
                  <li style={{ marginBottom: 8 }}>Using an AI that analyzes the text via a tagging system to identify key words and automatically organize the user's notes.</li>
                  <li style={{ marginBottom: 8 }}>Having a simple user-interface that reduces the number of steps a user has to take before writing their note</li>
            </ul>
          </div>
        </div>
      
        <div id="research" ref={(el) => (sectionsRef.current[1] = el)}  style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          {/* <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
              <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Research</div>
              {!!isMobile && <div style={{...style.sectionLine, ...(isMobile && mobileStyle.sectionLine)}} />}
          </div> */}
          <SubsectionTitle title='Research' color='#B59250' lineColor='#E0BE74' />

          
          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.atminIntroductionText}}>
            {!isMobile && <div style={{ ...style.mintText, ...style.bold, marginBottom: 8 }}>User Interviews</div>}
            <span>I interviewed 6 people who had full-time jobs or full-time studies while working on other projects or their own business. My goal for these interviews were to:</span>
            
            <ul style={{ marginBottom: 48 }}>
                  <li style={{ marginBottom: 8 }}>Identify writing habits and their note-taking processes </li>
                  <li style={{ marginBottom: 8 }}>Understand pain points that could result in difficult app usability</li>
                  <li style={{ marginBottom: 8 }}>Identify neccesary features or components that would improve the usability </li>
            </ul>
            {!isMobile && <div style={{ ...style.mintText, ...style.bold, marginBottom: 40 }}>Personas</div>}
            <img src={atmin_persona_1} style={{width: '100%', marginBottom: 40}} alt="persona 1 Johnny" />
            <img src={atmin_persona_2} style={{width: '100%'}} alt="persona 2 Sally" />
          </div>
        </div>

        <div id="ia" ref={(el) => (sectionsRef.current[2] = el)}  style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          {/* <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
              <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Research</div>
              {!!isMobile && <div style={{...style.sectionLine, ...(isMobile && mobileStyle.sectionLine)}} />}
          </div> */}
          <SubsectionTitle title='Information Architecture' color='#B59250' lineColor='#E0BE74' />

          
          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.atminIntroductionText}}>
            <div style={{ ...style.mintText, ...style.bold, marginBottom: 8 }}>IA</div>
            <span>For the app, I decided on a broad and shallow information architecture that consists of 3 main pages: </span>
            <ul style={{ marginBottom: 40 }}>
                  <li style={{ marginBottom: 8 }}>The Note page, which is the main home page </li>
                  <li style={{ marginBottom: 8 }}>The Library, where users can search for their notes </li>
                  <li style={{ marginBottom: 8 }}> The To-Do page, where users can list their tasks</li>
            </ul>
            <img src={atmin_ia} style={{width: '100%'}} alt="atmin information architecture diagram" />
            {!isMobile && <div style={{ ...style.mintText, ...style.bold, marginBottom: 8 }}>Task Flow</div>}
            <span>Sally wants to save her journal entry for the day</span>
            <img src={atmin_taskflow} style={{width: '100%'}} alt="atmin information architecture diagram" />
            {!isMobile && <div style={{ ...style.mintText, ...style.bold, marginBottom: 8, marginTop: 48 }}>Sketches</div>}
            <span>After completing my initial research and learning about certain frustrations and reservations users have with note apps, I decided to sketch some ideas. I’ve kept the UI simple because the main component would be the tag system that happens in the note itself.</span>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 12, rowGap: 24, marginTop: 24 }}>
                <img src={atmin_sketch_1} style={{width: isMobile ? '95%' : '25%', maxWidth: '45vw' }} alt="atmin sketch" />
                <img src={atmin_sketch_2}  style={{width: isMobile ? '95%' : '45%' }} alt="atmin sketch" />
                <img src={atmin_sketch_3} style={{width: isMobile ? '95%' : '25%', maxWidth: '45vw' }} alt="atmin sketch" />  
            </div>
            
          </div>
        </div>
        
        <div id="wireframing" ref={(el) => (sectionsRef.current[3] = el)} style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          {/* <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
              <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Wireframing</div>
              {!!isMobile && <div style={{...style.sectionLine, ...(isMobile && mobileStyle.sectionLine)}} />}
          </div> */}
          <SubsectionTitle title='Wireframing' color='#B59250' lineColor='#E0BE74' />

          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.atminIntroductionText}}>
            {!isMobile && <div style={{ ...style.mintText, ...style.bold, marginBottom: 8 }}>Wireframing</div>}
            <span>During my initial wireframes, I had the menu at the bottom of the page for the note, library, tasks, and account. After some trial and error, one of the challenges I came upon was having the note page as the homepage. It made me understand why so many note apps opened the app to the folders page, so my new goal was to learn how to design a simple note page that still functions the same way as a folders page.</span>
            <div style={{ marginTop: 40, marginBottom: 60, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32 })}}>
              <img src={atmin_wireframe_1} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />
              {!isMobile && <img src={atmin_wireframe_2} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />}
              <img src={atmin_wireframe_3} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />
              <img src={atmin_wireframe_4} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />
              <img src={atmin_wireframe_5} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />
              {!isMobile && <img src={atmin_wireframe_6} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />}
            </div>
            <span>In the next variation, I updated the overall user flow by having three main pages that could be accessed by swiping to the left and right with the icons at the top of the page. This way, the homepage could keep its simplicity having quite a lot of functionalities with the icons that appear above the textbox.</span>
            <div style={{ marginTop: 40, marginBottom: 52, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32})}}>
              <img src={isMobile ? atmin_wireframe_10 : atmin_wireframe_7} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />
              {!isMobile && <img src={atmin_wireframe_8} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />}
              {!isMobile && <img src={atmin_wireframe_9} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />}
              <img src={isMobile ? atmin_wireframe_7 : atmin_wireframe_10} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />
              <img src={atmin_wireframe_11} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />
              <img src={atmin_wireframe_12} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '16%'}} alt="atmin wireframe" />
            </div>
            {!isMobile && <div style={{ ...style.mintText, ...style.bold, marginBottom: 8 }}>Testing</div>}
            <span>I tested the wireframes with 3 users and had them complete 5 tasks as I watched them go through the app and took note of where they struggled to complete a task. This was the outcome:</span>
          
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: isMobile ? 12 : 40, marginTop: 40 }}>
              <span style={{ fontWeight: 600 }}>What went well</span>
              <ul style={{ listStyleType: 'disc' }}>
                <li style={{ marginBottom: 8 }}>Writing a note and using the tag feature was simple and straightforward for the users</li>
                <li style={{ marginBottom: 8 }}>Some users saw the tags as filters in the library instead of folders (opened new possibilities and perspective for me)</li>
                <li style={{ marginBottom: 8 }}>Making a new task was easy to navigate and understand the “I want to” prompt was enjoyable and “compelling”</li>
                <li style={{ marginBottom: 8 }}>Users were able to associate tasks with a project from the folder icon (success)</li>
              </ul>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: isMobile ? 12 : 40, marginTop: 40 }}>
              <span style={{ fontWeight: 600 }}>What needed to be improved</span>
              <ul style={{ listStyleType: 'disc' }}>
                <li style={{ marginBottom: 8 }}>The to-do button was mistaken for a “done” button, similar to Apple Notes</li>
                <li style={{ marginBottom: 8 }}>The @ icon did not stand out as a different function compared to the icons next to it</li>
                <li style={{ marginBottom: 8 }}>Skipped over the tag section when searching for a specific text </li>
                <li style={{ marginBottom: 8 }}>Selecting the texts to put them in an associated folder was frustrating</li>
                <li>Could not find the calendar view in the tasks page </li>

              </ul>
            </div>
            
          </div>
        </div>
      
        <div  id="design" ref={(el) => (sectionsRef.current[4] = el)} style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          <div style={{ display: 'flex', flex: width < 1400 ? 1 : 2, ...( isMobile ? {alignItems: 'center', marginBottom: 40 } : { flexDirection: 'column', justifyContent: 'space-between'}) }}> 
              <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>UI Design</div>
              {!!isMobile && <div style={{...style.sectionLine, ...(isMobile && mobileStyle.sectionLine)}} />}
              {!(width < 1400) &&
              <a href={prototype_link} target="_blank" rel="noreferrer"
                  style={style.button} 
                  className="circular-button-dark"  >
                        View<br/>Prototype
                </a>}
          </div>
              
          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.atminIntroductionText}}>
            {!isMobile && <div style={{ ...style.mintText, ...style.bold, marginBottom: 32 }}>Style Guide</div>}
            {!isMobile && <div style={{ display: 'flex', flexDirection: 'row'}}>
              <div style={{ flex:2, flexDirection: 'column'}}>
                <span>TYPOGRAPHY</span>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 32}}>
                  <div style={{ width: 115, display: 'flex', flexDirection: 'column'}}>
                    <span style={{color: '#979797', marginBottom: 50, fontSize: 18}}>TITLE</span>
                    <span style={{color: '#979797', marginBottom: 50}}>BODY</span>
                    <span style={{color: '#979797', marginBottom: 50}}>LABELS</span>
                    <span style={{color: '#979797', marginBottom: 50}}>BUTTONS</span>
                  </div>
                  <div style={{ display: 'flex', fontFamily: 'Roboto', flexDirection: 'column', paddingLeft: 24}}>
                    <span style={{fontSize: width < 1200 ? 12 : 18, color: '#fff', marginBottom: 50, fontWeight: 500 }}>ROBOTO, MEDIUM, 18 PX</span>
                    <span style={{fontSize: 12, color: '#fff', marginBottom: 50 }}>ROBOTO, REGULAR, 12 PX</span>
                    <span style={{fontSize: 12, color: '#fff', marginBottom: 50 }}>ROBOTO, REGULAR, 12 PX</span>
                    <span style={{fontSize: 10, color: '#fff', marginBottom: 50 }}>ROBOTO, REGULAR, 10 PX</span>
                  </div>
                </div>
              </div>
              <div style={{ flex: 1, flexDirection: 'column', paddingLeft: 70}}>
                <span>COLOUR PALETTE</span>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 32 }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: 36, marginRight: 40 }}>
                      <div style={{backgroundColor: '#80F3CA', borderRadius: '50%', height: 69, width: 69, marginBottom: 8}}/>
                      <div style={{ fontSize: 14, color: '#fff' }}>PRIMARY</div>
                      <div style={{ fontSize: 14, color: '#979797' }}>#80F3CA</div>
                    </div>
                    <div>
                      <div style={{backgroundColor: '#DADADA', borderRadius: '50%', height: 69, width: 69, marginBottom: 8}}/>
                      <div style={{ fontSize: 14, color: '#fff' }}>NEUTRAL 1</div>
                      <div style={{ fontSize: 14, color: '#979797' }}>#DADADA</div>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: 36, marginRight: 40 }}>
                      <div style={{backgroundColor: '#C38FFF', borderRadius: '50%', height: 69, width: 69, marginBottom: 8}}/>
                      <div style={{ fontSize: 14, color: '#fff' }}>SECONDARY</div>
                      <div style={{ fontSize: 14, color: '#979797' }}>#C38FFF</div>
                    </div>    
                    <div style={{ marginBottom: 36 }}>
                      <div style={{backgroundColor: '#979797', borderRadius: '50%', height: 69, width: 69, marginBottom: 8}}/>
                      <div style={{ fontSize: 14, color: '#fff' }}>NEUTRAL 2</div>
                      <div style={{ fontSize: 14, color: '#979797' }}>#979797</div>
                    </div>                
                  </div>
                  <div style={{ flex: 2 }}>
                  </div>
                </div>
              </div>
            </div>}
            {!isMobile && <div style={{ ...style.mintText, ...style.bold, marginBottom: 24 }}>UI Design</div>}
            <div>{isMobile ? 'Note Page:' : 'Note page and note page in task mode:'}</div>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', marginBottom: 24 }}>
              <video  style={{marginRight: 16, marginTop: 24, width: isMobile ? '100%' : '40%'}} className='VideoTag' autoPlay playsInline loop muted>
                    <source src={atmin_note_video} type='video/mp4'/>
              </video>
              {!!isMobile && <div style={{ marginTop: 24 }}>Task Mode:</div>}
              <video  style={{ marginTop: 24, width: isMobile ? '100%' : '40%'}} className='VideoTag' autoPlay playsInline loop muted>
                    <source src={atmin_task_video} type='video/mp4'/>
              </video> 
            </div>
            <div>{isMobile ? 'Library and folder tags:' : 'Library and To-Do page:'}</div>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', marginBottom: 24 }}>
              <video style={{marginRight: 16, marginTop: 24, width: isMobile ? '100%' : '40%'}} className='VideoTag' autoPlay playsInline loop muted>
                    <source src={atmin_library_video} type='video/mp4'/>
              </video>
              {!!isMobile && <div style={{ marginTop: 24 }}>To-Do Page:</div>}
              <video  style={{ marginTop: 24, width: isMobile ? '100%' : '40%'}} className='VideoTag' autoPlay playsInline loop muted>
                    <source src={atmin_todo_video} type='video/mp4'/>
              </video> 
            </div>

            {!!isMobile && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40, marginTop: 80}}>
              <a href={prototype_link} target="_blank" rel="noreferrer"
                style={style.button} 
                className="circular-button-dark"  >
                      View<br/>Prototype
              </a>
            </div>}
          </div>
        </div>
        
        <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>     
          <SubsectionTitle title='Takeaway' color='#B59250' lineColor='#E0BE74' />

          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.atminIntroductionText}}>
            <div style={{ width: '80%' }}>
              <span>This was a really fun project to work on and it challenged me to see a simple action like writing notes and tasks in a completely different perspective.</span>
              <span><br /><br />If I had more time, I would have done more usability testing and updated the design based on user’s feedback. </span>
              <span><br /><br />Thank you!</span>
            </div>
          </div>
        </div>

        <SectionTitle title={isMobile ? 'Next Project' : 'More Projects'} color='#E0BE74' />
        <NextProjectSection textColor='#979797' textColorMobile="#E0BE74" first='elentra' second='off2class' />
        <Footer light/> 
      </div>
    </div>
  );
}

const style = {
  nextProjectSubtitle: {
    color: '#979797', 
    fontSize: 14
  },
  nextProjectTitle: {
    color: '#979797', 
    fontWeight: 700,
    fontSize: 18
  },
  atminIntroductionText: {
    fontSize: 18,
    color: '#DADADA',
    fontWeight: 400
  },
  stepContainer: {
      display: 'flex',
      margin: "0px 10% 140px 10%",
  },
  mintText: {
    color: '#80F3CA',
    fontSize: 18
  },
  bold: {
    fontWeight: 600
  },
  lineSection: { 
    display: 'flex', 
    margin: "0px 0px 87px 152px", 
    alignItems: 'center'
  },
  sectionTitle: { 
    fontWeight: 700,
    fontSize: '2.2rem',
    fontFamily: 'Grifo',
    color: '#E0BE74'
  },
  projectTitle: {
    fontSize: 64,
    color: '#E0BE74',
    fontStyle: 'italic',
    fontWeight: 500,
  },
  projectSubtitle: {
    color: 'white',
    fontSize: 18,
    fontWeight: 300,
    paddingBottom: 5,
  },
  projectDescription: {
    color: 'white',
    fontSize: 18,
    fontWeight: 400,
    marginTop: 96,
    width: '50%'
  },
  projectTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    margin: 152,
  },
  projectStepTitle: {
    fontSize: 32,
    color: '#B59250',
    fontFamily: 'Grifo',
    fontWeight: 700
  },
  designProcessStep: {
    height: 50,
    color: '#80F3CA',
    fontSize: 18,
    marginBottom: 16,
    width: 120,
  },
  sectionLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#E0BE74',
    marginLeft: 45,
  },
  button: { width: 144,
    height: 144,
    border: '1px solid #E0BE74',
    backgroundColor: '#E0BE74',
    borderRadius: '50%',
    color: '#000',
    textDecoration: 'none',
    fontSize: 18,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button_alternate: { 
    width: 144,
    height: 144,
    border: '1px solid #E0BE74',
    borderRadius: '50%',
    color: '#E0BE74',
    textDecoration: 'none',
    fontSize: 18,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const mobileStyle = {
  container: {
    flexDirection: 'column',
    margin: "193px 24px 100px 24px",
    alignItems: 'center',
  },
  titleImageContainer: {
    display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'
  },
  lineSection: { 
    margin: "0px 32px 87px 32px", 
  },
  stepContainer: {
    display: 'flex',
    margin: "0px 32px 87px 32px",
  },
  projectStepTitle: {
    fontSize: 24
  },
  sectionLine: {
    marginRight: -32
  },
  nextProjectSubtitle: {
    color: '#E0BE74', 
    fontSize: 14
  },
  nextProjectTitle: {
    color: '#E0BE74', 
    fontWeight: 700,
    fontSize: 14,
    marginRight: 4
  },
}

export default AtminProjectPage;
